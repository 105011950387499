import "./comps.css";
import "../App.css";
import backIcon from "../assets/previous.png";
import { useNavigate } from "react-router-dom";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import chatEmoji from "../assets/chat-emoji.png";
import { ColorPalette } from "../global/const";
import { SpinningCircles } from "react-loading-icons";
import { useState } from "react";

export function Space({ height = "20px" }) {
  return <div style={{ height: height }}></div>;
}
export function SpaceHorizontal({ width = "10px" }) {
  return <div style={{ width: width }}></div>;
}
export function SecondaryBtn({ text, onClick, _width = null }) {
  return (
    <button
      className="secondary-button"
      style={{
        width: _width,
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export function PrimaryBtn({ text, _width = null, onClick }) {
  return (
    <button
      className="primary-button"
      style={{ width: _width }}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
export function TextButton({ children, onClick, size = "14px" }) {
  return (
    <div className="text-button" onClick={onClick} style={{ fontSize: size }}>
      {children}
    </div>
  );
}
export function RColumn({
  padding = null,
  children,
  horizontalAlign = "center",
  verticalAlignment = "start",
  height = null,
  width = null,
  bgColor = null,
  bgGradint = null,
}) {
  const style = {
    height: height,
    width: width,
    display: "flex",
    alignItems: horizontalAlign,
    justifyContent: verticalAlignment,
    flexDirection: "column",
    padding: padding,
    backgroundColor: bgColor,
    background: bgGradint,
  };
  return <div style={style}>{children}</div>;
}
export function RRow({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  maxWidth = null,
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexDirection: "row",
    maxWidth: maxWidth,
  };
  return <div style={style}>{children}</div>;
}
export function RGrid({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  gap = "12px",
  className = "",
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexWrap: "wrap",
    gap: gap,
  };
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

export function RText({
  children,
  size = "16px",
  weight = "normal",
  color = "white",
  align = null,
  normalFont = false,
  maxlines = null,
}) {
  return (
    <div
      style={{
        display: maxlines != null ? "-webkit-box" : null,
        WebkitBoxOrient: maxlines != null ? "vertical" : null,
        overflow: maxlines != null ? "hidden" : null,
        WebkitLineClamp: maxlines,
        wordBreak: "break-word",
        textOverflow: "ellipsis",
        color: color,
        fontSize: size,
        fontWeight: weight,
        textAlign: align,
      }}
      className={normalFont ? "normalFont" : ""}
    >
      {children}
    </div>
  );
}

export function RTitile({
  children,
  size = "22px",
  weight = "normal",
  color = "white",
  align = null,
}) {
  // font-family: 'Righteous', sans-serif;
  return (
    <div
      className="tit"
      style={{
        color: color,
        fontSize: size,
        fontWeight: weight,
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}

export function InputField({
  placeHolder,
  setValue,
  value,
  maxChar = null,
  width = "100%",
  type = null,
  bgColor = "black",
  padding = "16px 20px",
}) {
  return (
    <input
      type={type}
      maxLength={maxChar}
      value={value}
      onChange={setValue}
      placeholder={placeHolder}
      style={{
        margin: "6px 0px",
        color: "white",
        fontSize: "16px",
        padding: padding,
        boxSizing: "border-box",
        borderRadius: "12px",
        width: width,
        border: "none",
        outline: "none",
        backgroundColor: bgColor,
      }}
    />
  );
}

export function BackToHomeBtn() {
  const nav = useNavigate();
  function back() {
    nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
  }
  return (
    <div style={{ position: "fixed", top: "22px", left: "22px", zIndex: 20 }}>
      <img src={backIcon} width={"22px"} onClick={back} />
    </div>
  );
}

export function generateAlphaNumericCode() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let code = "";

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}
export const LoadingScreen = ({ txt = "Loading..." }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: ColorPalette.bgGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
      }}
    >
      <RText weight="bold" size="20px">
        {txt}
      </RText>
      <SpaceHorizontal />
      <SpinningCircles />
    </div>
  );
};

export const ScorePanel = ({ player, score, pos = "top", newEmoji = null }) => {
  //console.log("nemoo", newEmoji);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        zIndex: 3,
        position: "fixed",
        top: pos == "top" ? "12px" : "",
        left: "12px",
        bottom: pos == "bottom" ? "18px" : "",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "140px",
          height: "80px",
          background:
            "linear-gradient(to right, rgba(9, 22, 30, 0.8), rgba(0, 0, 0, 0.8))",
          borderRadius: "12px",
        }}
      >
        <RColumn height={"100%"} verticalAlignment="center">
          <RText weight="bold">{player?.slice(0, 10)}</RText>
          <RTitile weight="bold" size="20px">
            {score}
          </RTitile>
        </RColumn>
      </div>
      {newEmoji && (
        <img
          src={"gameEmoji/" + newEmoji + ".gif"}
          width={"50px"}
          height={"50px"}
        />
      )}
    </div>
  );
};
export const EmojiPanel = ({ onSelectedEmoji }) => {
  const [isOpen, setIsOpen] = useState(false);
  const emojiName = ["0", "1", "2", "3"];

  function onClickEmoji(name) {
    onSelectedEmoji(name);
    setIsOpen(false);
  }
  return (
    <div
      style={{
        zIndex: 3,
        position: "fixed",
        right: "12px",
        bottom: "64px",
        width: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isOpen && (
        <>
          {emojiName.map((name, ind) => (
            <div
              key={ind}
              style={{ cursor: "pointer" }}
              onClick={() => onClickEmoji(name)}
            >
              <img src={"gameEmoji/" + name + ".gif"} width={"34px"} />
            </div>
          ))}
        </>
      )}

      <img
        src={chatEmoji}
        width={"32px"}
        onClick={() => setIsOpen((prev) => !prev)}
      />
    </div>
  );
};
export const Padding = ({ children, padding = "8px 12px" }) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: padding }}>{children}</div>
    </div>
  );
};
