import {
  Padding,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import exitIcon from "../assets/exit.png";
import { useEffect, useState } from "react";
import { auth, db, logOut } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getRealBalance } from "../global/util";
import battleIcon from "../assets/battle.png";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { ToastContainer, toast } from "react-toastify";
import crownImg from "../assets/crown.png";
import { BaseInfo, TaskDoc } from "../global/const";
import { ContainerBorder, TabButton } from "../compnents/compes";
import TabController from "../home/tabController";

export const greenColor = "#30d938";
export const redColor = "#db3b3b";

const ProfilePage = ({ tgUsername, stats }) => {
  const [games, setGames] = useState([]);
  const nav = useNavigate();

  const [listType, setListType] = useState("games");

  const [pointsDoc, setPointsDoc] = useState(null);
  useEffect(() => {
    getUserDoc();
    getPoints();
  }, []);
  async function getPoints() {
    const p = await getDoc(doc(db, "user-points/" + auth.currentUser.uid));
    setPointsDoc(p.data());
  }
  const getUserDoc = async () => {
    const userDoc = doc(db, "users", auth.currentUser.uid);

    const gamesRefs = collection(db, "past_games");
    const q = query(
      gamesRefs,
      where("players", "array-contains", {
        uid: auth.currentUser.uid,
        username: auth.currentUser.displayName,
      }),
      orderBy("creationDate", "desc"),
      limit(10)
    );
    const querySnapshot = await getDocs(q);

    let nGames = [];
    querySnapshot.docs.forEach((element) => {
      nGames.push(element.data());
    });
    setGames(nGames);
  };

  const SingleGame = ({ data }) => {
    const date = format(data.creationDate, "iii d MMM HH:mm");
    const amountPnL =
      data.winner === auth.currentUser.uid
        ? data.bet_type === "points"
          ? data.price
          : (data.price ?? 5) * 0.9
        : -(data.price ?? 5);

    function GameMulti() {
      return (
        <>
          {data.players.map((p) => (
            <RRow horizontalAlign="start" key={p.uid}>
              {data.winner === p.uid ? (
                <>
                  <img src={crownImg} width={"20px"} />
                  <SpaceHorizontal width="8px" />
                </>
              ) : (
                <SpaceHorizontal width="28px" />
              )}
              <RText weight="bold" size="18px">
                {p.username}
              </RText>
              <Space height="8px" />
            </RRow>
          ))}
        </>
      );
    }
    function Game2v2() {
      return (
        <RRow horizontalAlign="space-evenly" width={"100%"}>
          <RColumn width={"50%"}>
            {data.winner === data.players[0].uid ? (
              <img src={crownImg} height={"24px"} />
            ) : (
              <Space height="24px" />
            )}
            <RText weight="bold" size="18px" align={"center"}>
              {data.players[0].username}
            </RText>
          </RColumn>
          <img src={battleIcon} width={"32px"} />
          <RColumn width={"50%"}>
            {data.winner === data.players[1].uid ? (
              <img src={crownImg} height={"24px"} />
            ) : (
              <Space height="24px" />
            )}
            <RText weight="bold" size="20px">
              {data.players[1].username}
            </RText>
          </RColumn>
        </RRow>
      );
    }
    return (
      <>
        <ContainerBorder
          padding="20px 20px"
          margin="10px 0px"
          heightMobile="120px"
          heightPc=""
        >
          <RRow horizontalAlign="space-between" verticalAlignment="start">
            <RColumn horizontalAlign="start">
              <RRow>
                <img
                  src={"gameIcons/" + data.gameUrl + ".png"}
                  width={"28px"}
                />
                <SpaceHorizontal width="8px" />
                <RText weight="bold" size="18px">
                  {BaseInfo.gamesName[data.gameUrl]}
                </RText>
              </RRow>

              <Space height="6px" />
              <RText
                align={"center"}
                weight="bold"
                size="18px"
                color={amountPnL >= 0 ? greenColor : redColor}
              >
                {amountPnL >= 0 ? "+" : "-"}
                {Math.abs(amountPnL).toFixed(
                  data.bet_type === "points" ? 0 : 1
                )}
                {data.bet_type === "points" ? " pts" : "$"}
              </RText>
            </RColumn>
            <RText color="grey" size="14px">
              {date}
            </RText>
          </RRow>

          <Space />
          {data.players_count === 2 && <Game2v2 />}
          {data.players_count > 2 && <GameMulti />}
        </ContainerBorder>
      </>
    );
  };

  function StatsW() {
    return (
      <>
        <Space />
        <Space />
        <RRow
          horizontalAlign="space-between"
          width={"90%"}
          verticalAlignment="start"
        >
          <RColumn>
            <RText size="17px">Total wins</RText>
            <RTitile>{stats?.total_win?.count ?? 0}</RTitile>
            <RTitile color={greenColor} size="16px">
              +${(stats?.total_win?.usd ?? 0).toFixed(1)}
            </RTitile>
          </RColumn>
          <RColumn>
            <RText size="17px">Total PnL</RText>
            <Space height="6px" />
            <RTitile
              color={
                (stats?.pnl ?? 0) === 0
                  ? "white"
                  : stats?.pnl < 0
                  ? redColor
                  : greenColor
              }
              size="22px"
            >
              {(stats?.pnl ?? 0) > 0 ? "+" : "-"}$
              {Math.abs(stats?.pnl ?? 0).toFixed(1)}
            </RTitile>
          </RColumn>
          <RColumn>
            <RText size="17px">Total losses</RText>
            <RTitile>{stats?.total_loss?.count ?? 0}</RTitile>
            <RTitile color={redColor} size="16px">
              -${(stats?.total_loss?.usd ?? 0).toFixed(1)}
            </RTitile>
          </RColumn>
        </RRow>
      </>
    );
  }
  function SingleHistoryPoint({ data }) {
    const date = format(data.date, "iii d MMM");

    const task = TaskDoc.tasks.find((t) => t.id === data.id);
    return (
      <ContainerBorder>
        <RRow horizontalAlign="space-between">
          <RColumn horizontalAlign="start">
            <RText size="14px" color="grey">
              {date}
            </RText>
            <RText>
              {task ? task.title : TaskDoc.points?.[data.id]?.title ?? data.id}
            </RText>
          </RColumn>
          <SpaceHorizontal />
          <RRow width={"140px"} horizontalAlign="end">
            <RTitile size="24px">+{data.points}</RTitile>
            <SpaceHorizontal width="4px" />
            <RText size="12px" color="grey">
              pts
            </RText>
          </RRow>
        </RRow>
      </ContainerBorder>
    );
  }
  return (
    <RColumn>
      <Space />
      <img
        src={auth.currentUser.photoURL}
        width={"100px"}
        style={{ borderRadius: "100%" }}
      />
      <Space height="12px" />
      <SecondaryBtn text={"logout"} onClick={() => logOut()} />
      <Space />
      <RRow width={"100%"} horizontalAlign="space-evenly">
        <TabButton
          onClick={() => setListType("games")}
          selected={listType === "games"}
        >
          Games
        </TabButton>
        <TabButton
          onClick={() => setListType("points")}
          selected={listType === "points"}
        >
          Points
        </TabButton>
      </RRow>
      <Space />
      {listType === "games" && (
        <>
          <StatsW />
          <Space />
          <RTitile>Last Games </RTitile>
          {games.map((element, index) => (
            <SingleGame data={element} key={index} />
          ))}
        </>
      )}
      {listType === "points" && (
        <>
          <RTitile>{pointsDoc?.points ?? 0} points</RTitile>
          <Space />
          <RText size="18px">Last Points </RText>
          <Space />
          {pointsDoc?.history.map((dat, i) => (
            <SingleHistoryPoint data={dat} key={dat.id + i} />
          ))}
        </>
      )}
      <Space height="100px" />
    </RColumn>
  );
};

export default ProfilePage;
