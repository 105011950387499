export const getPriceToken = async (token) => {
  if (token.toLowerCase() === "usdt" || token.toLowerCase() === "usdc")
    return 1;
  try {
    const response = await fetch(
      `https://api.coinbase.com/v2/exchange-rates?currency=${token}`
    );
    if (!response.ok) {
      throw new Error("Errore nella richiesta");
    }

    const data = await response.json();
    const usdPrice = data.data.rates["USD"];
    return usdPrice;
  } catch (e) {
    throw e;
  }
};
