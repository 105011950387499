import { useEffect, useRef, useState } from "react";
import {
  BackToHomeBtn,
  InputField,
  LoadingScreen,
  Padding,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
  TextButton,
  generateAlphaNumericCode,
} from "../compnents/otherCOmps";
import {
  Timestamp,
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import reloadImg from "../assets/reload.png";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import copyIcon from "../assets/copy.png";
import { Howl } from "howler";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseInfo, ColorPalette, getUserBalances } from "../global/const";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
//import { getRealBalance } from "../global/util";
import { httpsCallable } from "firebase/functions";
import { copyString, formatTimeAgoMinutes } from "../global/util";
import { getPriceToken } from "../global/apis";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Slider from "@mui/material/Slider";

import notificaSOund from "../audioFiles/notification.wav";
import { ContainerBorder } from "../compnents/compes";

export default function ChallengesPage() {
  const [listRooms, setListRooms] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    getListRooms();
  }, []);

  async function getListRooms() {
    setListRooms([]);
    const gameNameID = "games";

    let q = query(
      collection(db, gameNameID),
      where("state", "==", "waiting"),
      where("isOpen", "==", true),
      where("differtPlayerFinished", "==", true),
      where("players_count", "==", 1),
      where("createdBy", "!=", auth.currentUser.uid),
      //  orderBy("creationDate", "desc"),
      limit(15)
    );

    const resList = await getDocs(q);
    let rooms = [];
    resList.docs.forEach((element) => {
      rooms.push({
        ...element.data(),
        id: element.id,
      });
    });
    rooms.sort((a, b) => a.creationDate - b.creationDate);
    setListRooms(rooms);
  }

  function goToLobbyRoom(code, gameUrl) {
    nav("/lobby", {
      state: {
        invitedToGame: {
          code: code,
          gameUrl: gameUrl,
        },
      },
    });
  }

  function SingleRoom({ room }) {
    return (
      <ContainerBorder
        cursorPointer
        padding="10px 8px"
        onClick={() => goToLobbyRoom(room.code, room.gameUrl)}
      >
        <RRow
          horizontalAlign="space-between"
          verticalAlignment="top"
          height={"100%"}
        >
          <RRow height={"100%"}>
            <img src={"gameIcons/" + room.gameUrl + ".png"} width={"60px"} />
            <RColumn
              horizontalAlign="start"
              height={"100%"}
              verticalAlignment="top"
            >
              <RTitile>{BaseInfo.gamesName[room.gameUrl]}</RTitile>
              <Space height="4px" />
              <RText weight="bold" size="16px">
                by {room.players?.[0]?.username}
              </RText>
              {room.level !== false && (
                <RText size="14px">Level {room.level}</RText>
              )}
            </RColumn>
          </RRow>
          <RColumn horizontalAlign="end">
            <RText align={"end"} size="15px" color="grey">
              {formatTimeAgoMinutes(room.creationDate)}
            </RText>
            <Space height="8px" />
            <RText weight="bold" size="22px">
              {room.price}
              {room.bet_type === "points" ? " pts" : "$"}
            </RText>
          </RColumn>
        </RRow>
      </ContainerBorder>
    );
  }
  function RoomList() {
    return (
      <>
        {listRooms.length === 0 && (
          <RText align={"center"}>No challenges open, create one!</RText>
        )}
        {listRooms.map((room, index) => (
          <SingleRoom room={room} key={index} />
        ))}
      </>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <Space />
      <RRow horizontalAlign="end">
        <img onClick={getListRooms} src={reloadImg} width={"24px"} />
        <SpaceHorizontal />
      </RRow>

      <RColumn width={"100%"} horizontalAlign="center">
        <div style={{ width: "100%", maxWidth: "560px", display: "flex" }}>
          <div style={{ padding: "0px 10px", width: "100%" }}>
            <RTitile align={"center"}>Challenges</RTitile>

            <Space />
            <RoomList />
          </div>
        </div>
      </RColumn>
    </>
  );
}
