import { useEffect, useState } from "react";
import { HomePage } from "./homePage";
import profileIcon from "../assets/bar-chart.png";
import gamesIcon from "../assets/joystick-2.png";
import refIcon from "../assets/referrla.png";
import walletIcon from "../assets/wallet.png";
import {
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { BaseInfo, ColorPalette, setUserBalances } from "../global/const";
import headerImg from "../assets/headerbBG.jpeg";
import logoImg from "../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import copyIcon from "../assets/copy.png";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
//import { getRealBalance } from "../global/util";
import ProfilePage from "../profile/profilePage";
import WalletPage from "../wallet/walletPgae";
import ReferralPage from "../referral/referralPages";
import friendsIcon from "../assets/friends.png";
import FriendsPage from "../friends/friendsPage";
import leaderboardImg from "../assets/podium-3.png";
import { getPriceToken } from "../global/apis";
import GlobalPnLPage from "../ranks/globalPnlPage";
import medalImg from "../assets/medal.png";
import LevelsPage from "../levels/levelsPage";
import level1Img from "../assets/level1.png";
import level2Img from "../assets/level2.png";
import level3Img from "../assets/level3.png";
import { getLevelInd } from "../global/util";
import { BadgeWhiteBg, GlowBtn, MaxWidthContainer } from "../compnents/compes";
import casinoIcon from "../assets/slot-machine.png";
import telegramImg from "../assets/telegram.png";
import twitterImg from "../assets/twitter.png";
import { useNavigate } from "react-router-dom";
import websiteImg from "../assets/world-wide-web.png";
import ChallengesPage from "../lobby/challengesPage";

import challengImg from "../assets/target.png";
import { useGameType } from "../theme";
import { RiCloseCircleFill, RiTaskLine } from "@remixicon/react";
import TaskPage from "../tasks/taskPage";

import checkImg from "../assets/checklist.png";
import RanksPage from "../ranks/ranksPage";
import LoadingIcons from "react-loading-icons";
import { useParams } from "react-router-dom";

export const levelImgs = [level1Img, level2Img, level3Img];

export default function TabController({ tgUsername, initPage = 0 }) {
  const [currentTab, setCurrentTab] = useState(initPage);
  const [refCode, setRefCode] = useState("");
  const [stats, setStats] = useState(null);
  const [isMobile, setIsMobile] = useState(true);
  const [startBannerOpen, setStartBannerOpen] = useState(false);

  const nav = useNavigate();
  const { userBalances, updateUserBalances, setGameType, setUserPoints } =
    useGameType();
  const startPages = [
    { icon: walletIcon, label: "Wallet", index: 2 },
    { icon: friendsIcon, label: "Friends", index: 3 },
    { icon: leaderboardImg, label: "Ranks", index: 5 },
  ];
  const [pages, setPages] = useState(startPages);

  const [points, setPoints] = useState(0);

  useEffect(() => {
    setStartBannerOpen(!localStorage.getItem("fisrtTime"));

    const queryParams = new URLSearchParams(window.location.search);
    const pageParam = queryParams.get("page");
    if (pageParam === "task") {
      handleTabBtnClick(10);
    }
    if (pageParam === "wallet") {
      handleTabBtnClick(2);
    }
    if (pageParam === "referral") {
      handleTabBtnClick(1);
    }
    if (pageParam === "challenge") {
      handleTabBtnClick(9);
    }
    if (pageParam === "friends") {
      handleTabBtnClick(3);
    }

    setIsMobile(window.innerWidth < 800);
    window.addEventListener("resize", handleResize);
    const userDoc = doc(db, "users", auth.currentUser.uid);

    const unsub = onSnapshot(userDoc, (snapshot) => {
      if (snapshot.exists()) updateData(snapshot.data());
    });
    return () => unsub();
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 800);
  };

  useEffect(() => {
    const gameBtn = { icon: gamesIcon, label: "Games", index: 0 };
    const p = startPages;
    if (isMobile) {
      p.splice(1, 0, { icon: challengImg, label: "Duels", index: 9 });
      p.splice(2, 0, gameBtn);
    } else {
      p.splice(0, 0, gameBtn);
      p.push({ icon: medalImg, label: "Levels", index: 6 });
      p.splice(1, 0, { icon: profileIcon, label: "Stats", index: 4 });
      // p.splice(2, 0, { icon: casinoIcon, label: "Casino", index: 8 });
      p.splice(1, 0, { icon: challengImg, label: "Challenges", index: 9 });
      p.push({ icon: refIcon, label: "Referral", index: 1 });
      p.splice(1, 0, { icon: checkImg, label: "Tasks", index: 10 });
    }
    setPages(p);
  }, [isMobile]);

  const updateData = async (data) => {
    if (!data) return;
    updateUserBalances(data.balances);
    setRefCode(data["code"] ?? "");
    setPoints(data.points ?? 0);
    setUserPoints(data.points ?? 0);
    setStats(data["stats"] ?? null);
  };

  function Page() {
    if (currentTab === 0)
      return <HomePage tgUsername={tgUsername} isMobile={isMobile} />;
    if (currentTab === 3)
      return (
        <MaxWidthContainer>
          <FriendsPage userCode={refCode} isMobile={isMobile} />
        </MaxWidthContainer>
      );
    if (currentTab === 2)
      return (
        <MaxWidthContainer>
          <WalletPage />
        </MaxWidthContainer>
      );
    if (currentTab === 1)
      return (
        <MaxWidthContainer>
          <ReferralPage refCode={refCode} />
        </MaxWidthContainer>
      );
    if (currentTab === 4)
      return (
        <MaxWidthContainer>
          <ProfilePage stats={stats} tgUsername={tgUsername} />
        </MaxWidthContainer>
      );
    if (currentTab === 5)
      return (
        <MaxWidthContainer>
          <RanksPage />
        </MaxWidthContainer>
      );
    if (currentTab === 6)
      return (
        <MaxWidthContainer>
          <LevelsPage />
        </MaxWidthContainer>
      );
    if (currentTab === 9)
      return (
        <MaxWidthContainer>
          <ChallengesPage />
        </MaxWidthContainer>
      );
    if (currentTab === 10)
      return (
        <MaxWidthContainer>
          <TaskPage changeTabIndex={handleTabBtnClick} />
        </MaxWidthContainer>
      );
  }
  function handleTabBtnClick(i) {
    if (i === 8) {
      nav("/casino");
      return;
    }
    setCurrentTab(i);
  }
  function SingleTabBtn({ page }) {
    return (
      <>
        <div
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => handleTabBtnClick(page.index)}
        >
          <RColumn>
            <img src={page.icon} width={page.index === 0 ? "36px" : "24px"} />
            <Space height="6px" />
            <RText
              size="14px"
              weight="bold"
              color={
                page.index === currentTab ? ColorPalette.accentColor : "white"
              }
            >
              {page.label}
            </RText>
          </RColumn>
        </div>
      </>
    );
  }
  function SingleTabBtnDesktop({ page }) {
    return (
      <>
        <div
          style={{ cursor: "pointer", margin: "12px 0px", width: "142px" }}
          onClick={() => handleTabBtnClick(page.index)}
        >
          <RRow horizontalAlign="start" verticalAlignment="center">
            <img src={page.icon} width={"28px"} />
            <SpaceHorizontal height="6px" />
            <RText
              size="16px"
              weight="bold"
              color={
                page.index === currentTab ? ColorPalette.accentColor : "white"
              }
            >
              {page.label}
            </RText>
          </RRow>
        </div>
      </>
    );
  }
  function StartBanner() {
    if (startBannerOpen) {
      return (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: ColorPalette.accentColor2,
              width: "80%",
              maxWidth: "300px",
              borderRadius: "12px",
              position: "relative",
            }}
          >
            <RiCloseCircleFill
              size={"28px"}
              color="white"
              onClick={() => {
                setStartBannerOpen(false);
                localStorage.setItem("fisrtTime", false);
              }}
              style={{ position: "absolute", top: "-8px", right: "-8px" }}
            />
            <div style={{ padding: "42px 18px" }}>
              <RTitile>Don't forget!</RTitile>
              <Space height="12px" />
              <RText>
                🎉 Share your referral link now and secure a 30% referral fee
                once the casino goes live! 💰 <br /> Complete tasks to earn huge
                rewards like casino revenue shares, token supply, and much more!
                🎁
              </RText>
              <Space />
              <PrimaryBtn
                text={"Refer and earn!"}
                onClick={() => {
                  setStartBannerOpen(false);
                  localStorage.setItem("fisrtTime", false);
                  handleTabBtnClick(1);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  if (!isMobile) {
    return (
      <>
        <StartBanner />

        <div
          style={{
            overflowY: "auto",
            zIndex: 10,
            position: "fixed",
            width: "300px",
            height: "100vh",
            backgroundColor: ColorPalette.tabColor,
            top: 0,
            left: 0,
          }}
          className="highlighted-border no-scrollbar"
        >
          <RColumn>
            <Space height="44px" />
            <img
              style={{ cursor: "pointer" }}
              onClick={() => setGameType("init")}
              src={logoImg}
              width="100px"
            ></img>
          </RColumn>
          <Space height="32px" />

          <RColumn
            width={"100%"}
            horizontalAlign="center"
            verticalAlignment="start"
          >
            {pages.map((page, ind) => (
              <SingleTabBtnDesktop key={ind} page={page} />
            ))}
          </RColumn>
          <Space />
          {/*   <div style={{backgroundColor: "rgb(255, 218, 253)",boxShadow: "0 0 30px rgb(255, 118, 118)", width: "100%", height: "0.5px"}}></div> */}

          <RRow horizontalAlign="center">
            <a target="_blanck" href="https://t.me/TheGameHubXYZ">
              <img
                src={telegramImg}
                width={"22px"}
                style={{ cursor: "pointer" }}
              />
            </a>
            <SpaceHorizontal width="22px" />
            <a target="_blanck" href="https://x.com/TheGameHub_io">
              <img
                src={twitterImg}
                width={"22px"}
                style={{ cursor: "pointer" }}
              />
            </a>
            <SpaceHorizontal width="22px" />
            <a target="_blanck" href="https://thegamehub.xyz/">
              <img
                src={websiteImg}
                width={"22px"}
                style={{ cursor: "pointer" }}
              />
            </a>
          </RRow>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
        />
        <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
          <div style={{ width: "300px" }}></div>
          <div style={{ flex: 1 }}>
            <Page />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <StartBanner />

      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
        />
        {currentTab !== 10 && (
          <div
            onClick={() => handleTabBtnClick(10)}
            style={{
              position: "fixed",
              bottom: "120px",
              right: "12px",
              zIndex: 5,
              background: ColorPalette.bgGradient,
              width: "52px",
              height: "52px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              boxShadow: ColorPalette.shadow1,
            }}
          >
            <GlowBtn>$</GlowBtn>
          </div>
        )}
        <div
          style={{
            backgroundColor: ColorPalette.tabColor,
            padding: "14px 30px",
          }}
        >
          <RRow height="100%" horizontalAlign="space-between">
            <img
              onClick={() => setGameType("init")}
              src={logoImg}
              height="64px"
            ></img>
            <SpaceHorizontal />
            <RColumn
              verticalAlignment="start"
              horizontalAlign="start"
              width={"100%"}
            >
              <RRow>
                <BadgeWhiteBg size="24px" />
                <SpaceHorizontal />
                <RText size="16px" weight="bold">
                  Hello, {tgUsername}
                </RText>
              </RRow>
              <Space height="2px" />
              <RRow horizontalAlign="space-between" width={"100%"}>
                <RColumn horizontalAlign="start">
                  <div
                    style={{
                      backgroundColor: ColorPalette.accentColor2,
                      borderRadius: "8px",
                      padding: "2px 12px",
                    }}
                  >
                    {userBalances.isLoading === true ? (
                      <LoadingIcons.ThreeDots height={"8px"} width={"22px"} />
                    ) : (
                      <RText size="15px">
                        <strong>{userBalances.total_usd.toFixed(2)}</strong> USD
                      </RText>
                    )}
                  </div>
                  <Space height="2px" />
                  <div
                    style={{
                      padding: "0px 12px",
                    }}
                  >
                    <RText size="15px">
                      <strong>{points}</strong> pts
                    </RText>
                  </div>
                </RColumn>
                <RRow>
                  <img
                    src={medalImg}
                    width={"30px"}
                    onClick={() => setCurrentTab(6)}
                  />
                  <SpaceHorizontal />
                  <img
                    src={profileIcon}
                    width={"25px"}
                    onClick={() => setCurrentTab(4)}
                  />
                  <SpaceHorizontal />
                  <img
                    src={refIcon}
                    width={"25px"}
                    onClick={() => setCurrentTab(1)}
                  />
                </RRow>
              </RRow>
            </RColumn>
          </RRow>
        </div>
        <div
          style={{
            zIndex: 10,
            position: "fixed",
            width: "100%",
            height: "65px",
            background: `linear-gradient(to bottom, rgba(14, 22, 27, .85), ${ColorPalette.tabColor})`,
            bottom: 0,
            paddingTop: "12px",
          }}
        >
          <RRow
            width={"98%"}
            horizontalAlign="space-evenly"
            verticalAlignment="start"
            height={"100%"}
          >
            {pages.map((page, ind) => (
              <SingleTabBtn key={ind} page={page} />
            ))}
          </RRow>
        </div>
        <Page />
        <Space height="70px" />
      </div>
    </>
  );
}
