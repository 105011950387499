import { RColumn, RText, RTitile, Space } from "../compnents/otherCOmps";

import casinoBannImg from "../assets/Banner Casino.jpg";
import pokerBannImg from "../assets/poker banner.png";

import gamesBannerImg from "../assets/TG platform banner.jpg";
import { useGameType } from "../theme";
import { isTest } from "../global/const";
export default function StartBanners() {
  const bannStyle = { borderRadius: "12px", cursor: "pointer" };
  const { gameType, setGameType } = useGameType();
  function onClickBann(type) {
    setGameType(type);
  }
  function SingleBanner({ tit, img, type }) {
    const isComingSoon = isTest ? false : type !== "games";
    return (
      <div
        style={{ maxWidth: "700px" }}
        onClick={() => (!isComingSoon ? onClickBann(type) : () => {})}
      >
        <RColumn>
          <RText align={"center"} size="20px">
            {tit}
          </RText>
          <Space height="4px" />
          <div
            style={{
              position: "relative",
              width: "97%",
            }}
          >
            <img
              style={{
                ...bannStyle,
                filter: isComingSoon ? "blur(2px)" : null,
                opacity: isComingSoon ? 0.8 : null,
                width: "100%", // Assicura che l'immagine occupi tutto lo spazio del contenitore
              }}
              src={img}
            />

            {isComingSoon && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                Coming Soon
              </div>
            )}
          </div>
        </RColumn>
      </div>
    );
  }
  return (
    <>
      <RColumn horizontalAlign="center">
        <Space />
        <RTitile>The Game Hub</RTitile>
        <Space height="40px" />
        <SingleBanner type={"games"} tit={"Games"} img={gamesBannerImg} />
        <Space />
        <SingleBanner type={"casino"} tit={"Casino"} img={casinoBannImg} />
        <Space />
        <SingleBanner type={"poker"} tit={"Poker"} img={pokerBannImg} />
      </RColumn>
    </>
  );
}
